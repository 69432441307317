// src/axios.js
import axios from 'axios';

// 创建 Axios 实例
const axiosInstance = axios.create({
  baseURL: 'https://shawnapi.fuyaosports.com', // 替换为您的后端 API 地址
  headers: {
    'Content-Type': 'application/json',
  },
});

// 请求拦截器：在每个请求中添加 Authorization 头
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('jwt_token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// 响应拦截器：处理 401 未授权错误
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      // 清除本地存储的 Token
      localStorage.removeItem('jwt_token');
      // 重定向到登录页面
      window.location.href = '/admin/login';
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
