// src/main.js
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from './axios'; // 引入配置好的 Axios 实例

// 引入 Bootstrap 的 CSS 和 JS
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

// 引入 Bootstrap Icons
import 'bootstrap-icons/font/bootstrap-icons.css';

// 引入 Toast 插件
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';

// 创建 Vue 应用
const app = createApp(App);

// 将 Axios 实例添加到全局属性
app.config.globalProperties.$axios = axios;

// 使用插件
app.use(store);
app.use(router);
app.use(Toast, {
  // 可选的配置选项
  position: 'top-right',
  timeout: 5000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: false,
  closeButton: 'button',
  icon: true,
});

// 挂载 Vue 应用到 DOM
app.mount('#app');
