
<!-- src/App.vue -->
<template>
  <div id="app">
    <!-- 顶部导航栏 -->
    <nav class="navbar navbar-expand-lg navbar-light bg-light fixed-top">
      <div class="container-fluid justify-content-between">
        <!-- 添加 logo 和标题 -->
        <div class="d-flex align-items-center mx-auto">
          <router-link class="navbar-brand d-flex align-items-center" to="/">
            <img src="@/assets/fuyao.png" alt="Logo" class="logo me-2" />
            <span>Fuyao Client</span>
          </router-link>
        </div>

        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarNav">
          <ul class="navbar-nav ms-auto">
            <li class="nav-item" v-if="!isLoggedIn">
              <router-link to="/admin/login" class="nav-link">登录</router-link>
            </li>
            <li class="nav-item" v-else>
              <a href="#" class="nav-link" @click.prevent="logout">登出</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>

    <!-- 主内容区域 -->
    <div class="container-fluid mt-5">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
  computed: {
    isLoggedIn() {
      return this.$store.state.admin !== null;
    },
  },
  methods: {
    logout() {
      // 清除 Vuex 中的 admin 数据
      this.$store.commit('clearAuthData', null);
      // 跳转到登录页面
      this.$router.push('/admin/login');
    },
  },
};
</script>

<style>
body {
  padding-top: 56px; /* 适应固定导航栏的高度 */
}

.navbar-brand {
  font-size: 1.5rem;
  font-weight: bold;
}

.logo {
  width: 40px;
  height: auto;
}

.navbar {
  justify-content: center; /* 使内容居中 */
}
</style>
