// src/store/index.js
import { createStore } from 'vuex';

export default createStore({
  state: {
    user: null,
    token: localStorage.getItem('jwt_token') || null,
  },
  mutations: {
    setUser(state, payload) {
      state.user = payload.user;
      state.token = payload.token;
    },
    logout(state) {
      state.user = null;
      state.token = null;
    },
  },
  actions: {
    login({ commit }, { user, token }) {
      commit('setUser', { user, token });
    },
    logout({ commit }) {
      commit('logout');
      localStorage.removeItem('jwt_token');
    },
  },
  getters: {
    isAuthenticated(state) {
      return !!state.token;
    },
    getUser(state) {
      return state.user;
    },
  },
  modules: {},
});
